<template>
  <PageCard pageName="Manage Subscription" :iconClass="['fa', 'fa-users', 'fa-lg']">
    <template slot="page_content">
      <b-card title="Deliver subscription by ID">
        <div class="row justify-content-center">
          <div class="col-sm-10">
            <b-form-group
              label="Enter Subscription ID:"
              label-for="subscriptionId"
              :label-cols="3"
              :horizontal="true"
            >
              <div class="row">
                <div class="col-sm-6">
                  <b-form-input
                    id="subscriptionId"
                    type="text"
                    placeholder="Subscription ID ex: 12345"
                    v-model="subscriptionId"
                  ></b-form-input>
                </div>
                <div class="col-sm-3">
                  <b-button
                    type="submit"
                    size="sm"
                    variant="primary"
                    @click="deliverSubscription()"
                    >Re-run</b-button
                  >
                </div>
              </div>
            </b-form-group>
          </div>
        </div>
      </b-card>
      <b-card title="Bulk Delivers">
        <div class="row justify-content-center">
          <div class="col-sm-10">
            <b-button
              type="submit"
              size="sm"
              variant="primary"
              @click="bulkDeliver('Quarterly')"
              >Deliver Quarterly</b-button
            >
          </div>
        </div>
        <br>
        <div class="row justify-content-center">
          <div class="col-sm-10">
            <b-button
              type="submit"
              size="sm"
              variant="primary"
              @click="bulkDeliver('Annualy')"
              >Deliver Annualy</b-button
            >
          </div>
        </div>
      </b-card>
    </template>
  </PageCard>
</template>

<script>
import Vue from 'vue'
import store from '@/store/store'
import PageCard from '@/modules/core/components/layouts/PageCard'
import router from '@/router'

export default {
  name: 'SuperAdminManageSubscription',
  components: {
    PageCard
  },
  data() {
    return {
      subscriptionId: null
    }
  },

  beforeCreate() {
    let hasPermission = store.getters['Ability/manageSuperAdminBoard']
    if (!hasPermission) {
      Vue.toasted.show('Page access restricted. Please contact admin.', {
        icon: 'chain-broken',
        type: 'error'
      })
      router.push('/dashboard')
    }
  },
  methods: {
    deliverSubscription() {
      this.$http
        .get('/api/super_admin/subscriptions/' + this.subscriptionId + '/deliver', {
          handleErrors: true
        })
        .then(res => {
          this.subscriptionId = null
          Vue.toasted.show(res.data.message, {
            icon: 'user-circle',
            type: 'success'
          })
        })
    },
    bulkDeliver(schedule) {
      let url = ''
      if (schedule === "Quarterly") {
        url = '/api/super_admin/subscriptions/quarterly_deliver'
      } else {
        url = '/api/super_admin/subscriptions/annualy_deliver'
      }
      this.$http
        .post( url, {}, { handleErrors: false } )
        .then(res => {
          this.subscriptionId = null
          Vue.toasted.show(res.data.message, {
            icon: 'user-circle',
            type: 'success'
          })
        })
    }
  }
}
</script>
